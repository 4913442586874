import { useMemo, useState } from 'react';
import { set } from 'lockr';
import axios from 'axios';

import { getResponseErrorMessages } from 'utils/helpers';
import { useAppDispatch, useCurrentUser } from 'hooks';
import { msalInstance } from 'components/AuthWrapper';
import authorizationHeaders from 'common/authHeaders';
import { getToken, getOID } from 'utils/authService';
import ErrorModal from 'components/ErrorModal';
import { msalLogin } from 'utils/authService';
import { loginRequest } from 'config';
import api from 'api';

import { notify } from './notification/notificationSlice';

//TODO remove after backend send correct status code
const messages = [
  'User or Person already exists',
  'User has already been invited',
  // eslint-disable-next-line quotes
  "Provided Email doesn't exist", // VerifyEmail API
  'Access token cannot be null or empty.', // GetERPBankAccounts API
  'This account already exists and is active',
  'Range overlaps with an existing range for the BankAccountsId supplied', // AddUpdateBanksAccountsChecksAndRanges
  'Business owner must complete Microsoft Authentication flow to enable access to SMART-Hub and continue the payment workflow.', // VerifyCompliancePerson
];

const isTokenExpired = (errorResponse) => {
  return getToken() && errorResponse?.status === 401;
};

const isAccessNotAllowed = (errorResponse) => {
  return errorResponse?.status === 403;
};

const isValidationError = (errorResponse) => {
  return (
    errorResponse?.status === 400 &&
    Array.isArray(errorResponse?.data?.ErrorMessages) &&
    errorResponse.data.ErrorMessages.length > 0
  );
};

const isServerError = (errorResponse) => {
  if (!errorResponse) {
    set('serverTimeout', true);
    return true;
  }

  if (messages.includes(getResponseErrorMessages(errorResponse, true))) {
    return false;
  }

  return errorResponse?.status === 500;
};

const ApiInterceptors = () => {
  const { user } = useCurrentUser();
  const dispatch = useAppDispatch();

  const [serverError, setServerError] = useState(false);

  const resetToken = async () => {
    const result = await msalInstance.acquireTokenSilent({
      account: user,
      ...loginRequest,
    });

    msalLogin(result?.accessToken);

    return result?.accessToken;
  };

  useMemo(() => {
    api.interceptors.request.use((config) => {
      const accessToken = getToken();

      if (accessToken && config?.headers) {
        config.headers.Authorization = authorizationHeaders.bearer(accessToken);
        return config;
      }

      const oid = getOID();

      if (oid && config?.headers) {
        config.headers.Oid = oid;
      }

      return config;
    });

    api.interceptors.response.use(
      (response) => {
        if (response?.status && response?.data) {
          return response.data.hasOwnProperty('result')
            ? response.data.result
            : response.data;
        }
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        const { response: errorResponse } = error;

        if (isTokenExpired(errorResponse)) {
          originalRequest._retry = true;
          const token = await resetToken();

          axios.defaults.headers.common.Authorization =
            authorizationHeaders.bearer(token);

          return api(originalRequest);
        }

        if (isAccessNotAllowed(errorResponse)) {
          dispatch(
            notify({
              message: 'common:permissions.apiForbiddenError',
              variant: 'error',
              duration: 5000,
            }),
          );
        }

        if (isServerError(errorResponse)) {
          setServerError(true);
        }

        if (isValidationError(errorResponse)) {
          dispatch(
            notify({
              message: getResponseErrorMessages(errorResponse),
              variant: 'error',
              duration: 10000,
            }),
          );
        }

        return Promise.reject(error);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorModal serverError={serverError} setServerError={setServerError} />
  );
};

export default ApiInterceptors;
